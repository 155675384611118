<template>
  <div class="container" style="padding:30px">
    <el-alert
      title="如果文件沒有自動下載，請點擊按鈕手動下載。"
      type="success"
      center
      class="text-center"
      :closable="false"
    >
      <el-button type="success" style="margin:10px" @click="getTestFile">
        下載文件
      </el-button>
    </el-alert>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import testApi from "@/apis/tests";

export default {
  metaInfo() {
    return {
      title: "Download - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {
    fileToken() {
      return this.$route.params.fileToken;
    }
  },
  watch: {},

  mounted() {
    this.getTestFile();
  },

  methods: {
    async getTestFile() {
      const file = await testApi.getTestFile(this.fileToken);
      if (file) {
        var fileName = "Ivyway-Test.pdf";
        let blob = new Blob([file]);
        let fileURL = window.URL.createObjectURL(blob);
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.remove();
      } else {
        this.$message.error("對不起，您沒有權限下載本文件。");
      }
    }
  }
};
</script>

<style scoped></style>
